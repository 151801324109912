exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-us-about-us-js": () => import("./../../../src/templates/about-us/about-us.js" /* webpackChunkName: "component---src-templates-about-us-about-us-js" */),
  "component---src-templates-academy-academy-js": () => import("./../../../src/templates/academy/academy.js" /* webpackChunkName: "component---src-templates-academy-academy-js" */),
  "component---src-templates-article-article-js": () => import("./../../../src/templates/article/article.js" /* webpackChunkName: "component---src-templates-article-article-js" */),
  "component---src-templates-coverage-coverage-js": () => import("./../../../src/templates/coverage/coverage.js" /* webpackChunkName: "component---src-templates-coverage-coverage-js" */),
  "component---src-templates-home-home-js": () => import("./../../../src/templates/home/home.js" /* webpackChunkName: "component---src-templates-home-home-js" */),
  "component---src-templates-match-match-js": () => import("./../../../src/templates/match/match.js" /* webpackChunkName: "component---src-templates-match-match-js" */),
  "component---src-templates-matches-matches-js": () => import("./../../../src/templates/matches/matches.js" /* webpackChunkName: "component---src-templates-matches-matches-js" */),
  "component---src-templates-media-media-js": () => import("./../../../src/templates/media/media.js" /* webpackChunkName: "component---src-templates-media-media-js" */),
  "component---src-templates-news-news-js": () => import("./../../../src/templates/news/news.js" /* webpackChunkName: "component---src-templates-news-news-js" */),
  "component---src-templates-player-player-js": () => import("./../../../src/templates/player/player.js" /* webpackChunkName: "component---src-templates-player-player-js" */),
  "component---src-templates-privacy-policy-privacy-policy-js": () => import("./../../../src/templates/privacy-policy/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-privacy-policy-js" */),
  "component---src-templates-team-team-js": () => import("./../../../src/templates/team/team.js" /* webpackChunkName: "component---src-templates-team-team-js" */)
}

