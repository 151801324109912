/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { client } from 'src/apollo/client';
import { ParallaxProvider } from 'react-scroll-parallax';

import "./src/styles.css"

export const wrapPageElement = ({ element, props }) => {
  return (
    <ParallaxProvider>
      <ApolloProvider client={client}>
        {element}
      </ApolloProvider>
    </ParallaxProvider>
  )
}
