import ApolloClient from 'apollo-boost';
import fetch from 'isomorphic-fetch'

const token = '733a29680bfd351a78247ad46e3378'

export const client = new ApolloClient({
  fetch,
  uri: 'https://graphql.datocms.com/',
  request: (operation) => {
    operation.setContext({
      headers: {
        authorization: token
      }
    });
  }
});

